<template>
  <div>
    <div
      enter-class="opacity-0"
      enter-active-class="ease-out transition-medium"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-active-class="ease-out transition-medium"
      leave-to-class="opacity-0"
    >
      <div v-if="drawerOpen" class="z-40 fixed inset-0 transition-opacity">
        <div
          class="absolute inset-0 bg-gray-800 opacity-80"
          tabIndex="{0}"
          @click="drawerTigger"
        />
      </div>
    </div>

    <div
      id="footer"
      class="z-40 fixed bottom-0 left-0 right-0 w-full flex shadow-lg"
    >
      <div class="bg-red-700 pt-1 text-white w-full flex px-0">
        <div class="flex flex-grow w-1/5 items-center justify-center py-2">
          <router-link to="/submit">
            <div class="text-center w-full">
              <div class="text-xl">
                <i class="bi bi-camera"></i>
              </div>

              <div class="text-xs rounded-full pt-1">ส่งใบเสร็จ</div>
            </div>
          </router-link>
        </div>

        <div class="flex flex-grow w-1/5 items-center justify-center py-2">
          <router-link to="/condition">
            <div class="text-center w-full">
              <div class="text-white text-xl">
                <i class="bi bi-flag"></i>
              </div>
              <div class="text-xs text-white rounded-full pt-1">กติกา</div>
            </div>
          </router-link>
        </div>
        <div class="flex flex-grow w-1/5 items-center justify-center py-2">
          <router-link to="/">
            <div class="text-center w-full -mt-10">
              <div
                class="bg-green-700 text-white text-3xl w-16 h-16 pt-2 rounded-full shadow-xl"
              >
                <i class="bi bi-house-door"></i>
              </div>
            </div>
          </router-link>
        </div>
        <div class="flex flex-grow w-1/5 items-center justify-center py-2">
          <router-link to="/winner">
            <div class="text-center w-full">
              <div class="text-white text-xl">
                <i class="bi bi-trophy"></i>
              </div>
              <div class="text-xs text-white rounded-full pt-1">ประกาศผล</div>
            </div>
          </router-link>
        </div>
        <div class="flex flex-grow w-1/5 items-center justify-center">
          <router-link to="/history">
            <div class="text-center w-full">
              <div class="text-white text-xl">
                <img
                  :src="userLinePictureUrl"
                  alt=""
                  class="rounded-full mx-auto border-2 border-white"
                />
              </div>
              <div class="text-xs text-white rounded-full pt-1">โปรไฟล์</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { version } from "../../package";
import liff from "@line/liff";

export default {
  data() {
    return {
      drawerOpen: false,
      appVersion: version,
      isDone: false,
      userLineDisplayName: "",
      userLinePictureUrl: "",
    };
  },
  mounted() {},
  async created() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      const profile = await liff.getProfile();
      console.table(profile);
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;

      this.isDone = true;
    }
  },
  computed: {},
  methods: {
    drawerTigger() {
      // console.log("vue-layout:", this.drawerOpen);
      this.drawerOpen = !this.drawerOpen;
    },
  },
};
</script>
<style scoped>
#footer img {
  width: 30px;
  height: 30px;
}
#footer i {
  font-size: 22px;
}
</style>
