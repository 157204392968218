<template>
  <div class="bg-red-700 text-white font-bold text-2xl mb-3 py-5 text-center">
    ประกาศรายชื่อผู้โชคดี
  </div>
  <div class="content w-full p-5 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#00bcb4"
    ></loading>

    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <div>
        <div>
          <div class="">
            <div class="text-black text-md text-center"></div>
          </div>

          <div
            v-if="winData != null"
            v-for="(w, index) in winData.WD_DATA"
            :key="index"
          >
            <div class="mt-5">
              <div class="bg-red-700 rounded-xl shadow-lg">
                <div class="w-full px-10 py-3 text-white">
                  <div
                    class="bg-green-700 w-full mx-auto py-3 px-5 text-white text-lg font-bold rounded-3xl text-center"
                  >
                    {{ w.reward.RW_SPECIAL_CODE }}
                  </div>
                </div>
                <div class="text-white text-center px-5">
                  {{ w.reward.RW_NAME }}<br />มูลค่า {{ w.reward.RW_PRICE }} บาท
                  จำนวน {{ w.number_winner }} รางวัล<br /><br />
                </div>

                <div v-if="w.winner !== null">
                  <div class="bg-white p-5 rounded-lg shadow-md">
                    <div class="font-bold">รายชื่อผู้โชคดี</div>
                    <div class="overflow-x-auto rounded-lg relative mt-5">
                      <table
                        class="border-collapse w-full whitespace-no-wrap bg-white table-striped relative"
                      >
                        <thead>
                          <tr class="text-left text-sm bg-red-700">
                            <th
                              class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                            >
                              ลำดับ
                            </th>
                            <th
                              class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                            >
                              ชื่อ - นามสกุล
                            </th>
                            <th
                              class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                            >
                              เบอร์โทร
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(p, index) in w.winner"
                            :key="index"
                            class="text-sm"
                          >
                            <td
                              v-if="p.WC_CUS_FNAME !== ''"
                              class="fz12 border-b border-gray-200 text-center py-2 whitespace-nowrap"
                            >
                              {{ index + 1 }}
                            </td>
                            <td
                              v-if="p.WC_CUS_FNAME !== ''"
                              class="fz12 border-b border-gray-200 py-2 text-left whitespace-nowrap"
                            >
                              {{ p.WC_CUS_FNAME }} {{ p.WC_CUS_LNAME }}
                            </td>
                            <td
                              v-if="p.WC_CUS_FNAME !== ''"
                              class="fz12 border-b border-gray-200 py-2 text-center whitespace-nowrap"
                            >
                              {{ p.WC_CUS_MOBILE_HIDDEN }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div v-else class="px-5 py-10">
                  <hr />
                  <div class="text-center">
                    <div
                      class="pt-10 font-bold text-white text-xl mb-1 text-center"
                    >
                      รอติดตามประกาศรายชื่อผู้โชคดี
                    </div>
                    <h1 class="text-lg text-white">
                      วันที่ 2 ธันวาคม 2566 เวลา 18.00 น.
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";
export default {
  name: "Winner",
  data() {
    return {
      isLoading: true,
      fullPage: true,

      winData: null,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    this.isLoading = true;
    await this.service.getWinnerDate(0).then((data) => {
      this.winData = data.data[0];
    });
    this.isLoading = false;
  },
};
</script>
