<template>
  <div>
    <div class="bg-red-700 text-white font-bold text-2xl mb-3 py-5 text-center">
      ประวัติการส่งใบเสร็จ
    </div>
    <div class="content w-full p-5 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#00bcb4"
      ></loading>
      <div class="rounded-lg m-2">
        <div v-if="userIsRegister">
          <div class="flex flex-col text-center">
            <img
              :src="userLinePictureUrl"
              alt=""
              class="rounded-full h-24 w-24 mb-5 mx-auto"
            />

            <div class="text-2xl text-normal font-bold">
              {{ userLineDisplayName }}
            </div>
          </div>
        </div>
        <!-- <div class="flex flex-wrap mx-auto mt-2">
              <button
                @click="goTo('/redeem')"
                class="w-full text-xl font-bold rounded px-3 py-2    rounded-lg border-b-4 border-l-2 shadow-lg bg-green-700 border-green-900 text-white"
              >
                แลกของรางวัล
              </button>
              <button
                @click="goTo('/check-reward')"
                class="w-full text-xl font-bold rounded px-3 py-2 mt-1  rounded-lg border-b-4 border-l-2 shadow-lg bg-yellow-300 border-yellow-500 text-white"
              >
                ตรวจสอบของรางวัล
              </button>
              <button
                @click="goTo('/redeem-history')"
                class="w-full text-xl font-bold rounded px-3 py-2 mt-1  rounded-lg border-b-4 border-l-2 shadow-lg bg-pink-500 border-pink-600 text-white"
              >
                ประวัติการแลกของรางวัล
              </button>
            </div> -->

        <div class="overflow-x-auto rounded-lg overflow-y-auto relative mt-10">
          <table
            class="border-collapse w-full whitespace-no-wrap bg-white table-striped relative"
          >
            <thead>
              <tr class="text-left text-sm bg-green-700">
                <th
                  class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  วันที่
                </th>
                <th
                  class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  เลขที่
                </th>
                <th
                  class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  จำนวนเงิน
                </th>

                <th
                  class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  สถานะ
                </th>

                <th
                  class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  หมายเหตุ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(h, index) in custHistory"
                :key="index"
                class="text-xs"
              >
                <td
                  class="text-left border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  {{ h.CB_SUBMIT_DATETIME.substr(0, 10) }}<br />
                  {{ h.CB_SUBMIT_DATETIME.substr(11) }}
                </td>
                <td
                  class="border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  {{
                    h.CB_APPROVE_STATUS === "APPROVED"
                      ? h.CB_APPROVE_BILL_NO
                      : h.CB_SUBMIT_BILL_NO
                  }}
                </td>
                <td
                  class="border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  {{
                    h.CB_APPROVE_STATUS === "APPROVED"
                      ? h.CB_APPROVE_BUY_AMOUNT
                      : h.CB_SUBMIT_BUY_AMOUNT
                  }}
                </td>

                <td
                  class="border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  {{ h.CB_APPROVE_STATUS_TEXT }}
                </td>
                <td
                  class="border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  {{ h.CB_APPROVE_REMARK }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
th {
  font-weight: 300 !important;
}
</style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import liff from "@line/liff";
import router from "../router";
import Service from "../service/service";
export default {
  name: "SubmitHistory",
  data() {
    return {
      userLineId: null,
      userLineDisplayName: null,
      userLinePictureUrl: null,
      userIsRegister: false,
      isLoading: true,
      fullPage: true,
      custInfo: null,
      custHistory: null,
      countWait: 0,
      countApprove: 0,
      countReject: 0,
      custAmount: 0,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;
      this.service.getCustomerInfo(this.userLineId).then((data) => {
        if (data.isRegisted === 1) {
          this.userIsRegister = true;
          this.custInfo = data.custInfo;
          this.custAmount = data.custInfo.CUS_AMOUNT;
          this.service.getBillHistory(this.userLineId).then((rez) => {
            console.log(data);
            this.custHistory = rez.historyData;
            // this.custAmount = rez.total;
            rez.historyData.forEach((e) => {
              if (e.CB_APPROVE_STATUS == null) {
                this.countWait += 1;
              } else {
                if (e.CB_APPROVE_STATUS === "APPROVED") {
                  this.countApprove += 1;
                } else {
                  this.countReject += 1;
                }
              }
            });
          });
        } else {
          this.$swal
            .fire({
              html: "คุณยังไม่ได้ลงทะเบียน<br />กรุณาลงทะเบียนก่อนร่วมกิจกรรม",
              icon: "error",
              showCancelButton: false,
              confirmButtonText: "ไปลงทะเบียน  &rarr;",
            })
            .then((result) => {
              if (result.isConfirmed) {
                router.push("/register");
              }
            });
        }
        this.isLoading = false;
      });
    }
  },
  methods: {
    goTo(r) {
      router.push(r);
    },
    liffCloseWindow() {
      liff.closeWindow();
    },
  },
};
</script>
