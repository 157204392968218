<template>
  <div>
    <div class="bg-red-700 text-white font-bold text-2xl mb-3 py-5 text-center">
      ลงทะเบียน
    </div>
    <div class="content w-full p-5 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#00bcb4"
      ></loading>

      <div v-if="!isLoading">
        <div v-if="!userIsRegister" class="">
          <div class="rounded-lg m-2">
            <div class="relative flex w-full flex-wrap items-stretch mb-3">
              <label class="font-bold mb-2">ชื่อ</label>
              <input
                v-model="inputFName"
                type="text"
                placeholder="ชื่อ"
                class="px-3 py-3 relative border-0 w-full bg-white border-0 border-green-900 rounded-lg"
              />
            </div>
            <div class="relative flex w-full flex-wrap items-stretch mb-3">
              <label class="font-bold mb-2">นามสกุล</label>
              <input
                v-model="inputLName"
                type="text"
                placeholder="นามสกุล"
                class="px-3 py-3 relative border-0 w-full bg-white border-0 border-green-900 rounded-lg"
              />
            </div>
            <div class="relative flex w-full flex-wrap items-stretch mb-3">
              <label class="font-bold mb-2">เบอร์โทรศัพท์</label>
              <input
                v-model="inputMobile"
                v-mask="'###-###-####'"
                type="tel"
                placeholder="เบอร์มือถือ 10 หลัก"
                class="px-3 py-3 relative border-0 w-full bg-white border-0 border-green-900 rounded-lg"
              />
            </div>

            <div class="mt-10">
              <div class="mt-2">
                <div class="mt-1">
                  <label class="inline-flex">
                    <input
                      type="checkbox"
                      class="form-checkbox text-green-700 h-6 w-6 mt-1 bg-gray-50"
                      v-model="inputAccept1"
                      @click="showModalAccept1 = true"
                    />
                    <span class="ml-3"
                      >ข้าพเจ้าได้อ่านและยอมรับนโยบายการคุ้มครองข้อมูลส่วนบุคคลแล้ว</span
                    >
                  </label>
                </div>
                <div class="mt-5">
                  <label class="inline-flex">
                    <input
                      type="checkbox"
                      class="form-checkbox text-green-700 h-6 w-6 mt-1 bg-gray-50"
                      v-model="inputAccept2"
                      @click="showModalAccept2 = true"
                    />
                    <span class="ml-3"
                      >ข้าพเจ้าได้อ่านและยอมรับเงื่อนไขและกติกาการร่วมกิจกรรมแล้ว</span
                    >
                  </label>
                </div>
              </div>
            </div>
            <div class="flex mx-auto mt-5">
              <button
                @click="goSubmit"
                class="w-1/2 text-xl font-bold rounded px-3 py-2 mr-1 rounded-lg border-b-4 border-l-2 shadow-lg border-green-800 bg-gradient-to-r from-green-800 to-green-700 text-white"
              >
                ลงทะเบียน
              </button>
              <button
                @click="liffCloseWindow"
                class="w-1/2 text-xl font-bold rounded px-3 py-2 ml-1 rounded-lg border-b-4 border-l-2 shadow-lg bg-gray-300 border-gray-400 text-gray-500"
              >
                ยกเลิก
              </button>
            </div>
            <!-- end of step 2 -->
          </div>
        </div>
      </div>
      <div v-if="userIsRegister">
        <div class="p-1">
          <div class="py-10">
            <div class="flex flex-col text-center">
              <img
                :src="userLinePictureUrl"
                alt=""
                class="rounded-full h-32 w-32 mb-5 mx-auto"
              />

              <div class="text-2xl text-normal font-bold">
                {{ userLineDisplayName }}
              </div>
              <div class="text-xl text-black">
                คุณ{{ custInfo.CUS_FNAME }} {{ custInfo.CUS_LNAME }}
              </div>
              <div class="text-3xl text-green-900 font-extrabold mt-3">
                ลงทะเบียนสำเร็จ!
              </div>
            </div>
          </div>
        </div>
        <div class="flex mx-auto mt-2">
          <button
            @click="goTo('/submit')"
            class="w-1/2 text-xl font-bold rounded px-3 py-2 mr-1 rounded-lg border-b-4 border-l-2 shadow-lg border-green-800 bg-gradient-to-r from-green-800 to-green-700 text-white"
          >
            ส่งใบเสร็จ
          </button>
          <button
            @click="liffCloseWindow"
            class="w-1/2 text-xl font-bold rounded px-3 py-2 ml-1 rounded-lg border-b-4 border-l-2 shadow-lg bg-gray-300 border-gray-400 text-gray-500"
          >
            ปิด
          </button>
        </div>
      </div>

      <!-- modal -->
      <div v-if="showModalAccept1">
        <div
          class="z-40 fixed inset-0 bg-black bg-opacity-50 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0"
        >
          <div
            class="z-50 pacity-0 transform relative w-11/12 md:w-1/2 bg-white rounded shadow-lg transition-opacity transition-transform duration-300"
            style="height: 80vh"
          >
            <button
              @click="showModalAccept1 = false"
              class="absolute -top-3 -right-3 bg-green-700 hover:bg-green-900 text-2xl w-10 h-10 rounded-full focus:outline-none text-white"
            >
              &cross;
            </button>
            <button
              @click="showModalAccept1 = false"
              class="absolute -bottom-5 -left-1 w-full px-10 text-2xl font-bold px-3 py-2 m-1 rounded-b border-b-4 border-l-2 shadow-lg bg-green-700 border-green-800 text-white"
            >
              ยอมรับ
            </button>
            <div class="p-5 px-7">
              <h2 class="font-semibold text-green-700 text-2xl font-extrabold">
                {{ headerAccept1 }}
              </h2>
            </div>

            <div
              class="text-sm w-full px-7 pb-20 overflow-x-hidden overflow-y-auto"
              style="height: 65vh"
            >
              <div v-html="contentAccept1"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showModalAccept2">
        <div
          class="z-40 fixed inset-0 bg-black bg-opacity-50 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0"
        >
          <div
            class="z-50 pacity-0 transform relative w-11/12 md:w-1/2 bg-white rounded shadow-lg transition-opacity transition-transform duration-300"
            style="height: 80vh"
          >
            <button
              @click="showModalAccept2 = false"
              class="absolute -top-3 -right-3 bg-green-700 hover:bg-green-900 text-2xl w-10 h-10 rounded-full focus:outline-none text-white"
            >
              &cross;
            </button>
            <button
              @click="showModalAccept2 = false"
              class="absolute -bottom-5 -left-1 w-full px-10 text-2xl font-bold px-3 py-2 m-1 rounded-b border-b-4 border-l-2 shadow-lg bg-green-700 border-green-800 text-white"
            >
              ยอมรับ
            </button>
            <div class="p-5 px-7">
              <h2 class="font-semibold text-green-700 text-2xl font-extrabold">
                {{ headerAccept2 }}
              </h2>
            </div>

            <div
              class="text-sm w-full px-7 pb-20 overflow-x-hidden overflow-y-auto"
              style="height: 65vh"
            >
              <div v-html="contentAccept2"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of modal -->
    </div>
  </div>
</template>
<script>
import Service from "../service/service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "../router";
import liff from "@line/liff";

function unmask(maskedValue, mask) {
  let defaultTokens = ["#", "X", "S", "A", "a", "!"];
  let unmaskedValue = "";
  let isToken;

  for (let i = 0; i < maskedValue.length; i++) {
    isToken = false;
    for (let j = 0; j < defaultTokens.length; j++) {
      if (mask[i] == defaultTokens[j]) {
        isToken = true;
      }
    }

    if (isToken) {
      unmaskedValue += maskedValue[i];
    }
  }

  return unmaskedValue;
}

export default {
  name: "History",
  data() {
    return {
      userLineId: null,
      userLineDisplayName: null,
      userLinePictureUrl: null,
      userIsRegister: false,
      custInfo: null,
      isLoading: true,
      fullPage: true,
      campaignInfo: null,

      // content
      showModalAccept1: false,
      showModalAccept2: false,

      headerAccept1: null,
      contentAccept1: null,
      headerAccept2: null,
      contentAccept2: null,

      // register
      inputFName: "",
      inputLName: "",
      inputMobile: "",
      inputGender: "",
      inputAge: "",
      inputAccept1: false,
      inputAccept2: false,
      inputAddress: "",
      inputTambon: "",
      inputAmphur: "",
      inputProvince: "",
      inputZipcode: "",
      inputMobileRaw: "",
      inputRefCode: "",
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;
      await this.service.getCampaignInfo().then((data) => {
        this.campaignInfo = data.campaignInfo;
      });

      await this.service.getCustomerInfo(this.userLineId).then((data) => {
        if (data.isRegisted === 1) {
          this.step1 = false;
          this.step2 = false;
          this.custInfo = data.custInfo;
          this.userIsRegister = true;
        } else {
          this.userIsRegister = false;
          if (this.campaignInfo.C_ONLINE !== 1) {
            router.push("/off");
          }
        }
      });

      await this.service.getContent("PDPA").then((data) => {
        if (data.contentData) {
          this.headerAccept1 = data.contentData.PC_HEADER;
          this.contentAccept1 = data.contentData.PC_BODY;
        }
      });
      await this.service.getContent("CONDITION").then((data) => {
        if (data.contentData) {
          this.headerAccept2 = data.contentData.PC_HEADER;
          this.contentAccept2 = data.contentData.PC_BODY;
        }
      });
      this.isLoading = false;
    }
  },
  methods: {
    goTo(r) {
      router.push(r);
    },
    liffCloseWindow() {
      liff.closeWindow();
    },
    async goSubmit() {
      let isError = false;
      let txtError = "";
      if (this.inputFName === "") {
        isError = true;
        txtError += "<li>กรุณากรอกชื่อ</li>";
      }
      if (this.inputLName === "") {
        isError = true;
        txtError += "<li>กรุณากรอกนามสกุล</li>";
      }
      if (this.inputMobile === "") {
        isError = true;
        txtError += "<li>กรุณากรอกเบอร์มือถือ</li>";
      } else {
        this.inputMobileRaw = unmask(
          this.inputMobile,
          "###-###-####"
        ).toString();
        if (this.inputMobileRaw.length != 10) {
          isError = true;
          txtError += "<li>เบอร์มือถือไม่ถูกต้อง</li>";
        } else {
          await this.service
            .isMobileDuplicate(this.inputMobileRaw)
            .then((data) => {
              // console.log(data.isDup);
              if (data.checkResult === "DUPLICATE") {
                isError = true;
                txtError += "<li>เบอร์นี้มีการลงทะเบียนไว้แล้ว</li>";
              }
            });
        }
      }
      if (!this.inputAccept1) {
        isError = true;
        txtError += "<li>กรุณายอมรับนโยบายการคุ้มครองข้อมูล</li>";
      }
      if (!this.inputAccept2) {
        isError = true;
        txtError += "<li>กรุณายอมรับเกติกาการร่วมกิจกรรม</li>";
      }
      if (!isError) {
        let formData = new FormData();
        formData.append("lineid", this.userLineId);
        formData.append("fname", this.inputFName);
        formData.append("lname", this.inputLName);
        formData.append("mobile", this.inputMobileRaw);
        formData.append("ref_code", "");
        // formData.append("regGender", this.inputGender);
        // formData.append("regAge", this.inputAge);
        formData.append(
          "accept",
          JSON.stringify({
            PDPA: this.inputAccept1,
            CONDITION: this.inputAccept2,
          })
        );
        this.service.submitRegister(formData).then((data) => {
          if (data.isSuccess === 1) {
            this.custInfo = data.custInfo;
            this.userIsRegister = true;
            window.location.reload();
          } else {
            this.$swal({
              html: data.msgError,
              title: "พบข้อผิดพลาด",
              icon: "error",
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonColor: "#b91c1c",

              cancelButtonText: "ตกลง",
            });
          }
        });
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonColor: "#b91c1c",
          cancelButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>
